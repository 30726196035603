import { LazyExoticComponent, lazy } from "react";
import { Constants } from "utils/Constants";

const HomePage = lazy(() => import("pages/HomePage"));
const GamesCreatePage = lazy(() => import("pages/games/GamesCreatePage"));
const GamesNewGamePage = lazy(() => import("pages/games/GamesNewGamePage"));
const EnterCodePage = lazy(() => import("pages/games/GamesEnterCodePage"));
const GamesModerateGamePage = lazy(() => import("pages/games/GamesModerateGamePage"));
const GamesReportPage = lazy(() => import("pages/games/GamesReportPage"));
const GamesGamePage = lazy(() => import("pages/games/GamesGamePage"));
const GameSettingsPage = lazy(() => import("pages/games/GamesSettingsPage"));
const GamesJoinPage = lazy(() => import("pages/games/GamesJoinPage"));
const DiscoverPage = lazy(() => import("pages/discover/DiscoverPage"));
const DiscoverTopicPage = lazy(() => import("pages/discover/DiscoverTopicPage"));
const DiscoverSearchPage = lazy(() => import("pages/discover/DiscoverSearchPage"));
const RedirectPromptsTopicPage = lazy(() => import("pages/redirect/RedirectPromptsTopicPage"));
const GamesSpectatePage = lazy(() => import("pages/games/GamesSpectatePage"));
const StoryPage = lazy(() => import("pages/games/GamesStoryPage"));
const RedirectGamesStoryPage = lazy(() => import("pages/redirect/RedirectGamesStoryPage"));
const RedirectCompletedGamesPage = lazy(() => import("pages/redirect/RedirectCompletedGamesPage"));
const RedirectHallOfFamePage = lazy(() => import("pages/redirect/RedirectHallOfFamePage"));
const RandomPage = lazy(() => import("pages/RandomPage"));
const MyGamesPage = lazy(() => import("pages/games/GamesMyGamesPage"));
const GamesModeratePage = lazy(() => import("pages/games/GamesModeratePage"));
const DiscoverHallOfFamePage = lazy(() => import("pages/discover/DiscoverHallOfFamePage"));
const TermsPage = lazy(() => import("pages/footer/TermsPage"));
const PrivacyPage = lazy(() => import("pages/footer/PrivacyPage"));
const EventsPage = lazy(() => import("pages/footer/EventsPage"));
const FriendsPage = lazy(() => import("pages/FriendsPage"));
const SubscriptionsAboutPage = lazy(() => import("pages/subscriptions/SubscriptionsAboutPage"));
const SafetyPage = lazy(() => import("pages/footer/SafetyPage"));
const PermissionsPage = lazy(() => import("pages/PermissionsPage"));
const CompetitionTermsPage = lazy(() => import("pages/CompetitionTermsPage"));
const StudentWritingResourcesPage = lazy(() => import("pages/StudentWritingResourcesPage"));
const TeamPage = lazy(() => import("pages/footer/TeamPage"));
const LoginPage = lazy(() => import("pages/account/LoginPage"));
const ApproveUserPage = lazy(() => import("pages/account/ApproveUserPage"));
const ProfileChangePasswordPage = lazy(() => import("pages/profile/ProfileChangePasswordPage"));
const RecoverPasswordPage = lazy(() => import("pages/account/RecoverPasswordPage"));
const ClassesClassManagePage = lazy(() => import("pages/classes/ClassesClassManagePage"));
const ClassesCreatePage = lazy(() => import("pages/classes/ClassesCreatePage"));
const ClassesAddStudentsPage = lazy(() => import("pages/classes/ClassesAddStudentsPage"));
const RegisterPage = lazy(() => import("pages/account/RegisterPage"));
const ProfileDetailsPage = lazy(() => import("pages/profile/ProfileDetailsPage"));
const ProfileMyAvatarsPage = lazy(() => import("pages/profile/ProfileMyAvatarsPage"));
const ClassesClassPage = lazy(() => import("pages/classes/ClassesClassPage"));
const ClassesClassStudentPage = lazy(() => import("pages/classes/ClassesClassStudentPage"));
const ClassesClassStudentRepliesPage = lazy(() => import("pages/classes/ClassesClassStudentRepliesPage"));
const ClassesClassGamesPage = lazy(() => import("pages/classes/ClassesClassGamesPage"));
const ClassesClassShowcasePage = lazy(() => import("pages/classes/ClassesClassShowcasePage"));
const ClassesJoinPage = lazy(() => import("pages/classes/ClassesJoinPage"));
const ProfileReferAFriendPage = lazy(() => import("pages/profile/ProfileReferAFriendPage"));
const ContactPage = lazy(() => import("pages/footer/ContactPage"));
const NewsletterPage = lazy(() => import("pages/footer/NewsletterPage"));
const EduTechPage = lazy(() => import("pages/EduTechPage"));
const ProfileMySchoolPage = lazy(() => import("pages/profile/ProfileMySchoolPage"));
const ProfileJoinSchoolPage = lazy(() => import("pages/profile/ProfileJoinSchoolPage"));
const ProfileLeaveSchoolPage = lazy(() => import("pages/profile/ProfileLeaveSchoolPage"));
const AcceptInvitationPage = lazy(() => import("pages/account/AcceptInvitationPage"));
const SubscriptionsPurchaseSuccessPage = lazy(() => import("pages/subscriptions/SubscriptionsPurchaseSuccessPage"));
const GamesTemplatePage = lazy(() => import("pages/games/GamesTemplatePage"));
const GamesTemplatesPage = lazy(() => import("pages/games/GamesTemplatesPage"));
const GamesHistoryPage = lazy(() => import("pages/games/GamesHistoryPage"));
const GamesHomeworkPage = lazy(() => import("pages/games/GamesHomeworkPage"));
const GamesTemplateAssignToGroupPage = lazy(() => import("pages/games/GamesTemplateAssignToGroupPage"));
const GamesTemplateAssignAsHomeworkPage = lazy(() => import("pages/games/GamesTemplateAssignAsHomeworkPage"));
const GamesUpdateTemplatePage = lazy(() => import("pages/games/GamesUpdateTemplatePage"));
const ProfileMySubscriptionsPage = lazy(() => import("pages/profile/ProfileMySubscriptionsPage"));
const ReferAFriendPage = lazy(() => import("pages/footer/ReferAFriendPage"));
const FaqsPage = lazy(() => import("pages/footer/FaqsPage"));
const VideosPage = lazy(() => import("pages/footer/VideosPage"));
const ResourcesPage = lazy(() => import("pages/footer/ResourcesPage"));
const GamesReportsPage = lazy(() => import("pages/games/GamesReportsPage"));
const GamesHallOfFamePage = lazy(() => import("pages/games/GamesHallOfFamePage"));
const LandingWritingStaminaPage = lazy(() => import("pages/landing/LandingWritingStaminaPage"));
const LandingShakespearePage = lazy(() => import("pages/landing/LandingShakespearePage"));
const ClassesPage = lazy(() => import("pages/classes/ClassesPage"));

export const RouteList = {
    Home: "/",
    Help: "/help",
    GamesNewGame: "/games/new-game",
    GamesCreate: "/games/create",
    GamesPlay: "/games/play",
    Pricing: "/pricing",
    ProfileMySubscriptions: "/profile/my-subscriptions",
    ReferAFriend: "/refer-a-friend",
    TeachersReportingEverything: "/api/teachers/reporting/everything.csv",
    ProfileMySubscriptionsPurchaseSuccess: "/profile/my-subscriptions/purchase-success",
    TeachersSubscriptions: "/subscriptions/my-subscriptions",
    ClassesCreate: "/classes/create",
    ProfileMySchool: "/profile/my-school",
    ProfileJoinSchool: "/profile/my-school/join",
    ProfileLeaveSchool: "/profile/my-school/leave",
    Login: "/login",
    Discover: "/discover",
    DiscoverSearch: "/discover/search",
    DiscoverHallOfFame: "/discover/hall-of-fame",
    Contact: "/contact-us",
    Newsletter: "/newsletter",
    Signup: "/signup",
    ProfileChangePassword: "/profile/change-password",
    Random: "/random-page",
    GamesHome: "/games",
    GamesTemplates: "/games/templates",
    GamesShowcase: "/games/showcase",
    GamesHistory: "/games/history",
    GamesReports: "/games/reports",
    GamesModerate: "/games/moderate",
    GamesHomework: "/games/homework",
    Profile: "/profile",
    ProfileDetails: "/profile",
    ProfileMyAvatars: "/profile/my-avatars",
    ProfileReferralCode: "/profile/referral-code",
    ClassesJoin: "/classes/join",
    Terms: "/terms",
    Privacy: "/privacy",
    Events: "/events",
    Permissions: "/permissions",
    CompetitionTerms: "/comp-terms",
    StudentWritingResources: "/student-writing-resources",
    Team: "/team",
    Safety: "/safety",
    Friends: "/friends",
    EduTech: "/edutech",
    Faqs: "/faqs",
    Videos: "/videos",
    Resources: "/resources",
    LandingWritingStamina: "/writing-stamina",
    LandingShakespeare: "/shakespeare",
    Writelike: Constants.webUrl,
    Classes: "/classes",
};

interface IRouteTableItem {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: LazyExoticComponent<any>;
}

export const RouteTable: Array<IRouteTableItem> = [
    {
        path: RouteList.GamesPlay,
        Component: EnterCodePage,
    },
    {
        path: RouteList.GamesNewGame,
        Component: GamesNewGamePage,
    },
    {
        path: RouteList.GamesCreate,
        Component: GamesCreatePage,
    },
    {
        path: RouteList.GamesHome,
        Component: MyGamesPage,
    },
    {
        path: RouteList.GamesModerate,
        Component: GamesModeratePage,
    },
    {
        path: RouteList.GamesTemplates,
        Component: GamesTemplatesPage,
    },
    {
        path: RouteList.GamesHistory,
        Component: GamesHistoryPage,
    },
    {
        path: RouteList.GamesHomework,
        Component: GamesHomeworkPage,
    },
    {
        path: RouteList.GamesReports,
        Component: GamesReportsPage,
    },
    {
        path: RouteList.GamesShowcase,
        Component: GamesHallOfFamePage,
    },
    {
        path: "/story/:code([A-Za-z0-9]{6})",
        Component: RedirectGamesStoryPage,
    },
    {
        path: "/games/completed-games/:code([A-Za-z0-9]{6})",
        Component: RedirectCompletedGamesPage,
    },
    {
        path: "/recover-password/:hash",
        Component: RecoverPasswordPage,
    },
    {
        path: "/approve-user/:hash",
        Component: ApproveUserPage,
    },
    {
        path: "/accept-invitation/:hash",
        Component: AcceptInvitationPage,
    },
    {
        path: "/games/templates/:id([0-9]+)/edit/",
        Component: GamesUpdateTemplatePage,
    },
    {
        path: "/games/moderate/:code([A-Za-z0-9]{6})",
        Component: GamesModerateGamePage,
    },
    {
        path: "/games/reports/:code([A-Za-z0-9]{6})",
        Component: GamesReportPage,
    },
    {
        path: "/games/templates/:id([0-9]+)/assign-to-class",
        Component: GamesTemplateAssignToGroupPage,
    },
    {
        path: "/games/templates/:id([0-9]+)/assign-to-individuals",
        Component: GamesTemplateAssignAsHomeworkPage,
    },
    {
        path: "/games/templates/:id([0-9]+)",
        Component: GamesTemplatePage,
    },
    {
        path: "/classes/:id([0-9]+)/manage",
        Component: ClassesClassManagePage,
    },
    {
        path: "/discover/hall-of-fame/:code([A-Za-z0-9]{6})",
        Component: StoryPage,
    },
    {
        path: "/games/history/:code([A-Za-z0-9]{6})",
        Component: StoryPage,
    },
    {
        path: "/games/spectate/:code([A-Za-z0-9]{6})",
        Component: GamesSpectatePage,
    },
    {
        path: "/games/settings/:code([A-Za-z0-9]{6})",
        Component: GameSettingsPage,
    },
    {
        path: "/games/join/:code([A-Za-z0-9]{6})",
        Component: GamesJoinPage,
    },
    {
        path: "/games/:code([A-Za-z0-9]{6})",
        Component: GamesGamePage,
    },
    {
        path: "/prompts/browse/:friendlyUrl/:prompt",
        Component: RedirectPromptsTopicPage,
    },
    {
        path: "/prompts/browse/:friendlyUrl",
        Component: RedirectPromptsTopicPage,
    },
    {
        path: "/games/hall-of-fame/:code([A-Za-z0-9]{6})",
        Component: RedirectHallOfFamePage,
    },
    {
        path: RouteList.DiscoverSearch,
        Component: DiscoverSearchPage,
    },
    {
        path: RouteList.Discover,
        Component: DiscoverPage,
    },
    {
        path: RouteList.DiscoverHallOfFame,
        Component: DiscoverHallOfFamePage,
    },
    {
        path: "/discover/prompts/:friendlyUrl/:prompt",
        Component: DiscoverTopicPage,
    },
    {
        path: "/discover/prompts/:friendlyUrl",
        Component: DiscoverTopicPage,
    },
    {
        path: "/classes/:id([0-9]+)",
        Component: ClassesClassPage,
    },
    {
        path: "/classes/:classId([0-9]+)/add-students",
        Component: ClassesAddStudentsPage,
    },
    {
        path: "/classes/:classId([0-9]+)/games",
        Component: ClassesClassGamesPage,
    },
    {
        path: "/classes/:classId([0-9]+)/showcase",
        Component: ClassesClassShowcasePage,
    },
    {
        path: "/classes/:classId([0-9]+)/:studentId([0-9]+)",
        Component: ClassesClassStudentPage,
    },
    {
        path: "/classes/:classId([0-9]+)/:studentId([0-9]+)/replies",
        Component: ClassesClassStudentRepliesPage,
    },
    {
        path: RouteList.Classes,
        Component: ClassesPage,
    },
    {
        path: RouteList.ProfileReferralCode,
        Component: ProfileReferAFriendPage,
    },
    {
        path: RouteList.Resources,
        Component: ResourcesPage,
    },
    {
        path: RouteList.Faqs,
        Component: FaqsPage,
    },
    {
        path: RouteList.Videos,
        Component: VideosPage,
    },
    {
        path: RouteList.ReferAFriend,
        Component: ReferAFriendPage,
    },
    {
        path: RouteList.ProfileMySubscriptionsPurchaseSuccess,
        Component: SubscriptionsPurchaseSuccessPage,
    },
    {
        path: RouteList.ProfileMySubscriptions,
        Component: ProfileMySubscriptionsPage,
    },
    {
        path: RouteList.Contact,
        Component: ContactPage,
    },
    {
        path: RouteList.Newsletter,
        Component: NewsletterPage,
    },
    {
        path: RouteList.Home,
        Component: HomePage,
    },
    {
        path: RouteList.ClassesCreate,
        Component: ClassesCreatePage,
    },
    {
        path: RouteList.ProfileJoinSchool,
        Component: ProfileJoinSchoolPage,
    },
    {
        path: RouteList.ProfileMySchool,
        Component: ProfileMySchoolPage,
    },
    {
        path: RouteList.ProfileLeaveSchool,
        Component: ProfileLeaveSchoolPage,
    },
    {
        path: RouteList.Pricing,
        Component: SubscriptionsAboutPage,
    },
    {
        path: RouteList.Random,
        Component: RandomPage,
    },
    {
        path: RouteList.Terms,
        Component: TermsPage,
    },
    {
        path: RouteList.Privacy,
        Component: PrivacyPage,
    },
    {
        path: RouteList.Events,
        Component: EventsPage,
    },
    {
        path: RouteList.Permissions,
        Component: PermissionsPage,
    },
    {
        path: RouteList.CompetitionTerms,
        Component: CompetitionTermsPage,
    },
    {
        path: RouteList.StudentWritingResources,
        Component: StudentWritingResourcesPage,
    },
    {
        path: RouteList.Team,
        Component: TeamPage,
    },
    {
        path: RouteList.Safety,
        Component: SafetyPage,
    },
    {
        path: RouteList.Friends,
        Component: FriendsPage,
    },
    {
        path: RouteList.Login,
        Component: LoginPage,
    },
    {
        path: RouteList.Signup,
        Component: RegisterPage,
    },
    {
        path: RouteList.ProfileChangePassword,
        Component: ProfileChangePasswordPage,
    },
    {
        path: RouteList.ProfileDetails,
        Component: ProfileDetailsPage,
    },
    {
        path: RouteList.ProfileMyAvatars,
        Component: ProfileMyAvatarsPage,
    },
    {
        path: RouteList.ClassesJoin,
        Component: ClassesJoinPage,
    },
    {
        path: RouteList.EduTech,
        Component: EduTechPage,
    },
    {
        path: RouteList.LandingWritingStamina,
        Component: LandingWritingStaminaPage,
    },
    {
        path: RouteList.LandingShakespeare,
        Component: LandingShakespearePage,
    },
];

export const RouteLink = {
    game: (c: string) => `/games/${c}`,
    settings: (c: string) => `/games/settings/${c}`,
    editTemplate: (id: number) => `/games/templates/${id}/edit`,
    discoverHallOfFameStory: (c: string) => `/discover/hall-of-fame/${c}`,
    gamesCompletedGame: (c: string) => `/games/history/${c}`,
    spectate: (c: string) => `/games/spectate/${c}`,
    gamesJoin: (c: string) => `/games/join/${c}`,
    classesClassManage: (id: number | string) => `/classes/${id}/manage`,
    teacherSubscriptionAssign: (id: number) => `/subscriptions/my-subscriptions/${id}/assign`,
    classesAddStudents: (id: number | string) => `/classes/${id}/add-students`,
    teacherReportUser: (id: number) => `/api/teachers/reporting/user/${id}.csv`,
    teacherReportGame: (id: number) => `/api/teachers/reporting/game/${id}.csv`,
    teacherReportClass: (id: number) => `/api/teachers/reporting/group/${id}.csv`,
    approveUser: (hash: string) => `/approve-user/${hash}`,
    acceptInvitation: (hash: string) => `/accept-invitation/${hash}`,
    recoverPassword: (hash: string) => `/recover-password/${hash}`,
    promptsTopic: (friendlyUrl: string, prompt?: string) =>
        `/discover/prompts/${friendlyUrl}${prompt ? `/${prompt}` : ""}`,
    discoverSearch: (q: string) => `/discover/search?q=${q}`,
    gamesTemplateAssignToGroup: (id: number) => `/games/templates/${id}/assign-to-class`,
    gamesTemplateAssignAsHomework: (id: number) => `/games/templates/${id}/assign-to-individuals`,
    gamesTemplate: (id: number | string) => `/games/templates/${id}`,
    gamesReport: (c: string) => `/games/reports/${c}`,
    gamesModerate: (c: string, userId?: number) => `/games/moderate/${c}${userId ? `?u=${userId}` : ""}`,
    classesClass: (classId: number | string) => `/classes/${classId}`,
    classesClassGames: (classId: number) => `/classes/${classId}/games`,
    classesClassShowcase: (classId: number) => `/classes/${classId}/showcase`,
    classesClassStudent: (classId: number, studentId: number) => `/classes/${classId}/${studentId}`,
    classesClassStudentReplies: (classId: number, studentId: number) => `/classes/${classId}/${studentId}/replies`,
    gamesModerateTemplate: (id: number) => `/games/moderate?t=${id}`,
    gamesModerateClass: (id: number) => `/games/moderate?c=${id}`,
    gamesReportsTemplate: (id: number) => `/games/reports?t=${id}`,
    gamesReportsClass: (id: number) => `/games/reports?c=${id}`,
    gamesHomeworkTemplate: (id: number) => `/games/homework?t=${id}`,
    gamesHomeworkClass: (id: number) => `/games/homework?c=${id}`,
};
